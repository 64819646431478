.Alert__error_2za0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 14px 0;
  background-color: #ffe9e8;
  border-radius: 4px;
}

  .Alert__error-title_xLzy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 1 100%;
            flex: 0 1 100%;
    -webkit-align-items: center;
            align-items: center;
    font-size: 14px;
    color: #ff8484;
}

  .Alert__error-title-icon_3oqQ {
      margin: 0 10px;
      color: #ff8484;
}
