

.SimpleLink__simpleLink_YkQ4 {
  position: relative;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

  @media (max-width: 767px) {

.SimpleLink__simpleLink_YkQ4 {
    padding-left: 16px;
  }
}

  .SimpleLink__simpleLink_YkQ4 a {
    display: inline;
    padding-top: 1px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: underline;
    cursor: pointer;
    border-bottom: none;
}

  .SimpleLink__simpleLink_YkQ4 a:hover {
      text-decoration: none;
}

  .SimpleLink__simpleLink_white_3Qog a {
    color: #fff;
}
