.UserEdit__UserEdit_1ATi {
  position: relative;
  display: block;
}

  .UserEdit__UserEdit_submitting_3cq3 .UserEdit__content_3Y2b,
    .UserEdit__UserEdit_submitting_3cq3 .UserEdit__action_3Q7P:first-child {
      opacity: 0.5;
}

  .UserEdit__UserEdit_removeSuccess_3gYP {
    position: absolute;
    right: 0;
    left: 0;
}

  @media (max-width: 1280px) {.UserEdit__UserEdit_1ATi {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    padding-right: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__box_1eEq {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      min-height: 100%;
      background-color: #ffffff;
  }

      .UserEdit__UserEdit_1ATi .UserEdit__box_removeSuccess_wAI_ {
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
  }
      .UserEdit__UserEdit_1ATi .UserEdit__item_2MNC:not(:last-child) {
        margin-bottom: 6px;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__heading_pJrB,
    .UserEdit__UserEdit_1ATi .UserEdit__save_38Uj {
      display: block;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      padding: 0 16px 20px;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__title_1o-E {
      font-size: 18px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
  }

    .UserEdit__UserEdit_1ATi .UserEdit__content_3Y2b {
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 1;
              flex: 1;
      min-height: 100%;
      padding: 16px 16px 36px;
      margin-bottom: auto;
      background-color: #ffffff;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__content_contacts_2HWU {
      padding-bottom: 20px;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__content_traveler_3oIG {
      display: block;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__heading_pJrB {
      position: relative;
      z-index: 2;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding: 16px;
      background-color: #ffffff;
      border-bottom: 1px solid #eceff5;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__buttons_642q {
      z-index: 2;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding: 24px 16px 0;
  }

    .UserEdit__UserEdit_1ATi .UserEdit__button_3OTP {
      margin-left: auto;
  }

      .UserEdit__UserEdit_1ATi .UserEdit__button_3OTP:first-child {
        margin-left: 0;
  }
}

.UserEdit__heading_pJrB,
.UserEdit__save_38Uj {
  display: none;
}

.UserEdit__save_38Uj {
  width: 50%;
}

.UserEdit__notification_zXk8 {
  margin-bottom: 24px;
}

@media (max-width: 1280px) {

.UserEdit__notification_zXk8 {
    margin: 16px 16px 0;
  }
}

.UserEdit__notification_content_1ZRz > button {
    display: inline-block;
}

.UserEdit__content_3Y2b {
  display: block;
  width: 100%;
}

.UserEdit__error_3jQr {
  display: block;
  margin: -15px 0 8px;
}

@media (max-width: 1280px) {

.UserEdit__error_3jQr {
    margin: 16px 16px 0;
  }
}

.UserEdit__item_2MNC {
  display: block;
}

@media (max-width: 1280px) {

.UserEdit__item_2MNC {
    width: 100%;
    padding-right: 0;
  }
}

.UserEdit__item_2MNC:not(:last-child) {
    margin-bottom: 6px;
}

.UserEdit__actions_2acM {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 40px;
}

@media (max-width: 1280px) {

.UserEdit__actions_2acM {
    display: none;
  }
}

.UserEdit__action_3Q7P {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 158px;
  margin-left: 8px;
}

.UserEdit__buttons_642q {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-bottom: 19px;
}

.UserEdit__button_3OTP {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-left: 25px;
}

.UserEdit__confirm_for_remove_1WR8 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 56px;
  padding: 0 24px;
  margin: -24px -24px 20px -24px;
  background-color: #64686C;
}

.UserEdit__confirm_for_remove_success_1wvN {
    z-index: 3;
    margin-top: -28px;
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_1WR8 {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: auto;
    padding: 16px 16px 20px;
    margin: 24px 15px 0;
    border-radius: 4px;
  }

    .UserEdit__confirm_for_remove_1WR8:before {
      position: absolute;
      bottom: -6px;
      left: 17px;
      z-index: 999;
      width: 7px;
      height: 7px;
      content: '';
      background-color: #64686C;
      -webkit-transform: rotate(45deg) translateX(-50%);
              transform: rotate(45deg) translateX(-50%);
  }
}

.UserEdit__confirm_for_remove_content_3j9e {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 15px;
    color: #ffffff;
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_content_3j9e {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      width: 100%;
  }
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_message_3JTP {
      -webkit-flex: 0 1 100%;
              flex: 0 1 100%;
  }
}

.UserEdit__confirm_for_remove_loader_3JHC {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_loader_3JHC {
      position: static;
      margin: 5px auto 0;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
  }
}

.UserEdit__confirm_for_remove_actions_3f0i {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_actions_3f0i {
      margin: 17px auto 0;
  }
}

.UserEdit__confirm_for_remove_actions_cancel_3dIM {
      width: 100px;
      margin-left: 8px;
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_actions_cancel_3dIM {
        width: 124px;
  }
}

.UserEdit__confirm_for_remove_actions_confirm_2yS7 {
      width: 124px;
}

.UserEdit__confirm_for_remove_actions_text_1Xs0 {
      font-weight: 600;
}

.UserEdit__confirm_for_remove_overlay_2PDV {
    position: absolute;
    top: 56px;
    right: 0;
    bottom: 88px;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, .4);
}

@media (max-width: 1280px) {

.UserEdit__confirm_for_remove_overlay_2PDV {
      display: none;
  }
}

.UserEdit__close_2O9J {
  margin-right: 4px;
  color: #222a37;
  outline: none;
}

.UserEdit__loader_18BI {
  display: none;
}

@media (max-width: 1280px) {

.UserEdit__loader_18BI {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    pointer-events: none;
  }
}
