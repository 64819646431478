.Wrapper__wrapper_ELpb {
  display: block;
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
}

  @media (max-width: 1023px) {.Wrapper__wrapper_ELpb {
    max-width: calc(100% - 32px);
  }
}

  @media (max-width: 767px) {.Wrapper__wrapper_ELpb {
    max-width: 720px;
  }
}
