

.Button__Button_2OxR {
  position: relative;
  z-index: 1;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-width: 160px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  background-color: #c4d600;
  border: 0;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0);
  opacity: 1;
  transition: background-color 0.2s, box-shadow 0.2s, opacity 0.2s, border 0.2s;
}

  @media (max-width: 767px) {

.Button__Button_2OxR {
    min-width: 100%;
  }
}

  .Button__Button_2OxR:focus {
    outline: 1px auto rgb(77, 144, 254);
}

  .Button__Button_2OxR:hover {
    background-color: #b3c400;
}

  .Button__Button_2OxR:active {
    background-color: #acbb00;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
}

  .Button__Button_2OxR:disabled {
    cursor: default;
    opacity: 0.38;
}

  .Button__Button_2OxR:disabled .Button__inner_2QhA {
      cursor: default;
}

  .Button__Button_loading_2V_- .Button__inner_2QhA {
      opacity: 0;
}

  .Button__Button_loading_2V_- .Button__icon_SmuB {
      opacity: 0;
}

  .Button__Button_loading_2V_- .Button__loader_3V44 {
      opacity: 1;
}

  .Button__Button_size_slim_Q98F {
      height: 32px;
}

  .Button__Button_size_slim_Q98F .Button__inner_2QhA {
        font-size: 13px;
}

  .Button__Button_size_medium_12zk {
      height: 48px;
}

  .Button__Button_size_medium_12zk .Button__inner_2QhA {
        font-size: 14px;
}

  .Button__Button_size_large_1292 {
      height: 56px;
}

  .Button__Button_size_large_1292 .Button__inner_2QhA {
        font-size: 16px;
}

  .Button__Button_width_auto_3u81 {
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
}

  .Button__Button_width_full_KXUE {
      min-width: 100%;
}

  .Button__Button_theme_primary_2Kf0 {
      background-color: #f2a900;
}

  .Button__Button_theme_primary_2Kf0:hover {
        background-color: #dd9a24;
}

  .Button__Button_theme_primary_2Kf0:active {
        background-color: #d29323;
}

  .Button__Button_theme_primary_2Kf0:disabled {
        background-color: #f2a900;
}

  .Button__Button_theme_outline_1u3Z {
      background-color: transparent;
      border: 1px solid #9e9e9e;
}

  .Button__Button_theme_outline_1u3Z .Button__inner_2QhA {
        color: rgba(0, 0, 0, 0.54);
}

  .Button__Button_theme_outline_1u3Z:hover {
        background-color: #f5f5f5;
        border: 1px solid #9e9e9e;
}

  .Button__Button_theme_outline_1u3Z:hover .Button__inner_2QhA {
          color: rgba(0, 0, 0, 0.54);
}

  .Button__Button_theme_outline_1u3Z:active {
        background-color: transparent;
        border: 1px solid #9e9e9e;
}

  .Button__Button_theme_outline_1u3Z:active .Button__inner_2QhA {
          color: rgba(0, 0, 0, 0.54);
}

  .Button__Button_theme_border_1OW7 {
      background-color: transparent;
      border: 1px solid #f2a900;
}

  .Button__Button_theme_border_1OW7 .Button__inner_2QhA {
        color: #f2a900;
}

  .Button__Button_theme_border_1OW7:hover {
        background-color: transparent;
        border: 1px solid #dd9a24;
}

  .Button__Button_theme_border_1OW7:hover .Button__inner_2QhA {
          color: #dd9a24;
}

  .Button__Button_theme_border_1OW7:active {
        background-color: transparent;
        border: 1px solid #d29323;
}

  .Button__Button_theme_border_1OW7:active .Button__inner_2QhA {
          color: #d29323;
}

  .Button__Button_theme_border_1OW7:disabled {
        background-color: transparent;
        border: 1px solid #dce7aa;
}

  .Button__Button_theme_danger_2gn8 {
      background-color: #ff515a;
}

  .Button__Button_theme_danger_2gn8:hover {
        background-color: #DA5059;
}

  .Button__Button_theme_danger_2gn8:active {
        background-color: #DA5059;
}

  .Button__Button_theme_dark_31o5 {
      background-color: #9EA0A4;
}

  .Button__Button_theme_dark_31o5:hover {
        background-color: #8F9294;
}

  .Button__Button_theme_dark_31o5:active {
        background-color: #8F9294;
}

  .Button__Button_theme_timer_3foB:disabled {
        background-color: rgba(242,169,0,0.2);
        box-shadow: inset 0 1px 0 1px rgba(255,255,255,0.06), 0 1px 0 0 rgba(0,0,0,0.08);
        opacity: 1;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7 {
    min-width: 244px;
    color: #c4d600;
    border-color: currentColor;
    border-radius: 4px;
}

  @media (max-width: 767px) {

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7 {
      min-width: 100%;
  }
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7 .Button__inner_2QhA {
      width: 100%;
      text-align: center;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7 .Button__inner_2QhA,
    .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7 .Button__icon_SmuB {
      color: rgba(0, 0, 0, 0.87);
      border-color: #c4d600;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:hover {
      color: #b3c400;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:hover .Button__inner_2QhA,
      .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:hover .Button__icon_SmuB {
        color: rgba(0, 0, 0, 0.87);
        border-color: #b3c400;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:active {
      color: #acbb00;
}

  .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:active .Button__inner_2QhA,
      .Button__Button_color_green_1leD.Button__Button_theme_border_1OW7:active .Button__icon_SmuB {
        color: rgba(0, 0, 0, 0.87);
        border-color: #acbb00;
}

  .Button__Button_border_radius_small_3G_l {
        border-radius: 4px;
}

  .Button__Button_border_radius_large_3T3S {
        border-radius: 8px;
}

  .Button__Button_border_radius_large_bottom_1Jf1 {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
}

.Button__icon_SmuB {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  height: 32px;
  padding-right: 14px;
  padding-left: 14px;
  border-left: 1px solid #c4d600;
}

.Button__inner_2QhA {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.Button__loader_3V44 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
