

.Columns__columns_2Urf {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: -8px;
  margin-bottom: -24px;
  margin-left: -8px;
}

  .Columns__columns_smallMargin_ZLFU {
    margin-right: -12px;
    margin-left: -12px;
}

  @media (max-width: 767px) {

.Columns__columns_2Urf {
    margin-bottom: -24px;
  }
}
