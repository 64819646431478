

.Loader__loader_2__O {
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 200%;
  -webkit-animation: Loader__spin_hiyx 0.75s linear infinite;
          animation: Loader__spin_hiyx 0.75s linear infinite;
}

  .Loader__loader_size_large_1Jv6 {
      width: 32px;
      height: 32px;
      border-width: 7px;
}

  .Loader__loader_theme_green_16pH {
      border-color: #E0E0E0;
      border-top-color: #C1C000;
}

@-webkit-keyframes Loader__spin_hiyx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader__spin_hiyx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
