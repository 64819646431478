

.Text__Text_2dbL {
  display: inline-block;
  max-width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  word-break: break-word;
  word-wrap: break-word;
  cursor: inherit;
}

  .Text__Text_size_smallest_1fr2 {
    font-size: 10px;
    line-height: 14px;
}

  .Text__Text_size_small_MF56 {
    font-size: 12px;
    line-height: 16px;
}

  .Text__Text_size_semi_Ds1E {
    font-size: 16px;
    line-height: 16px;
}

  .Text__Text_size_demi_1jXd {
    font-size: 18px;
    line-height: 18px;
}

  .Text__Text_size_large_1rCS {
    font-size: 20px;
    line-height: 20px;
}

  .Text__Text_size_largest_1Y26 {
    font-size: 24px;
    line-height: 24px;
}

  .Text__Text_size_subtitle_3agb {
    font-size: 16px;
    line-height: 24px;
}

  .Text__Text_size_title_2DmL {
    font-size: 28px;
    line-height: 36px;
}

  .Text__Text_weight_semibold_13Wn {
    font-weight: 500;
}

  .Text__Text_weight_bold_38Ov {
    font-weight: 600;
}

  .Text__Text_overflow_ellipsis_Nc5x {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

  .Text__Text_disabled_25XZ {
    opacity: 0.5;
}

  @media (max-width: 767px) {
    .Text__Text_mobileDemi_uks_.Text__Text_size_subtitle_3agb {
      font-size: 14px;
      line-height: 20px;
  }
}

  .Text__Text_2dbL b {
    font-weight: bold;
}

.Text__color_black_2Eve {
  color: rgba(0, 0, 0, 0.87);
}

.Text__color_gray_3iT7 {
  color: rgba(0, 0, 0, 0.54);
}

.Text__color_green_1Lv7 {
  color: #c4d600;
}

.Text__color_red_Gx-S {
  color: #d22630;
}

.Text__color_white_38Ib {
  color: #fff;
}

.Text__decoration_blink_15ch {
  text-decoration: blink;
}

.Text__decoration_line-through_21DQ {
  text-decoration: line-through;
}

.Text__decoration_overline_1w4V {
  text-decoration: overline;
}

.Text__decoration_underline_TIqm {
  text-decoration: underline;
}
