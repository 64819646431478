.PaperTitle__root_YJgU {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 72px;
  padding: 0 32px
}

.PaperTitle__icon_18yd {
  color: inherit
}

@media (max-width: 1280px) {
  .PaperTitle__root_YJgU {
    height: 56px;
    padding: 0 16px
  }

  .PaperTitle__icon_18yd {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    outline: none
  }
}
