.Column__column_Zd7z {
  z-index: 20;
  display: block;
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 24px;
}

.Column__column_smallMargin_2qPS {
    padding-right: 12px;
    padding-left: 12px;
}

.Column__column_Zd7z.Column__column_zindex_initial_1VB- {
    z-index: auto;
    z-index: initial;
}

.Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 19;
}

.Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 18;
}

.Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 17;
}

.Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 16;
}

.Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 15;
}

.Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z + .Column__column_Zd7z {
    z-index: 14;
}

.Column__column_auto_zindex_3AAJ {
    z-index: auto !important;
}

@media (max-width: 767px) {

    .Column__column_Zd7z:not(:last-child) {
      margin-bottom: 24px;
  }

    .Column__column_smallMargin_2qPS {
      margin-bottom: 20px;
  }
}

.Column__width_desktop_25_1zbB {
        -webkit-flex: 0 0 25%;
                flex: 0 0 25%;
        max-width: 25%;
}

@media (max-width: 767px) {
        .Column__width_mobile_25_2PoG {
          -webkit-flex: 0 0 25%;
                  flex: 0 0 25%;
          max-width: 25%;
  }
}

.Column__width_desktop_30_36uE {
        -webkit-flex: 0 0 30%;
                flex: 0 0 30%;
        max-width: 30%;
}

@media (max-width: 767px) {
        .Column__width_mobile_30_VC-p {
          -webkit-flex: 0 0 30%;
                  flex: 0 0 30%;
          max-width: 30%;
  }
}

.Column__width_desktop_50_2f_I {
        -webkit-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
}

@media (max-width: 767px) {
        .Column__width_mobile_50_3Pav {
          -webkit-flex: 0 0 50%;
                  flex: 0 0 50%;
          max-width: 50%;
  }
}

.Column__width_desktop_70_1OJf {
        -webkit-flex: 0 0 70%;
                flex: 0 0 70%;
        max-width: 70%;
}

@media (max-width: 767px) {
        .Column__width_mobile_70_32eX {
          -webkit-flex: 0 0 70%;
                  flex: 0 0 70%;
          max-width: 70%;
  }
}

.Column__width_desktop_75_172t {
        -webkit-flex: 0 0 75%;
                flex: 0 0 75%;
        max-width: 75%;
}

@media (max-width: 767px) {
        .Column__width_mobile_75_3cR1 {
          -webkit-flex: 0 0 75%;
                  flex: 0 0 75%;
          max-width: 75%;
  }
}

.Column__width_desktop_100_3UuL {
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%;
}

@media (max-width: 767px) {
        .Column__width_mobile_100_1kRL {
          -webkit-flex: 0 0 100%;
                  flex: 0 0 100%;
          max-width: 100%;
  }
}
