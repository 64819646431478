.Icon__icon_9m1m {
  /* "display: block" is necessary to avoid problems when line breaks are considered a space */
  display: block;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  line-height: 0;
  color: inherit;
  text-align: center;
  pointer-events: inherit;
  cursor: inherit;
  fill: currentColor
}

.Icon__color_currentColor_3izf {
  color: currentColor
}

.Icon__color_red_17aL {
  color: #d22630
}

.Icon__color_black_t-nb {
  color: #000
}

.Icon__color_white_3C5U {
  color: #fff
}

.Icon__color_green_13f2 {
  color: #c4d600
}

.Icon__color_gray_3eze {
  color: #757575
}

.Icon__color_yellow_32RA {
  color: #f2a900
}

.Icon__color_dark-green_1NrJ {
  color: #509e2f
}
