.Paper__root_2sv- {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(34, 42, 55, 0.04), 0 4px 12px rgba(34, 42, 55, 0.04)
}

@media (max-width: 1280px) {
  .Paper__root_2sv- {
    border-radius: 12px
  }
}
